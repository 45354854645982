<template>
  <div class="flex h-screen">
    <AppSidebar v-if="shouldShowSidebar" />
    <!-- Flex-grow is used here to make sure that the main content takes the remaining space -->
    <div class="flex-1 flex flex-col">
      <!-- Padding is added and adjusted conditionally -->
      <main :class="shouldShowSidebar ? 'pl-72' : 'p-4'">
        <!-- Ensure that router-view takes the full width and height available -->
        <router-view class="w-full h-full" />
      </main>
    </div>
  </div>
</template>


<script>
import AppSidebar from "@/components/layouts/Sidebar.vue";
import { ref, computed } from "vue";
import { useRoute } from "vue-router";

export default {
  name: 'App',
  components: {
    AppSidebar
  },
  setup() {
    const route = useRoute();
    const shouldShowSidebar = computed(() => !['/login', '/register'].includes(route.path));
    const sidebarWidth = ref('64'); // Sidebar width in rem

    return {shouldShowSidebar, sidebarWidth};
  }
}
</script>

<style>
  /* Global styles */
.flex-1 {
  flex: 1 1 0%;
}
/* Other styles */
</style>