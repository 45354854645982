<script setup>

</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M12 14L21 9L12 4L3 9L12 14Z" stroke="black"/>
    <path d="M12 13.9999L18.16 10.5779C18.9705 12.636 19.2006 14.8771 18.825 17.0569C16.2886 17.3031 13.8972 18.3535 12 20.0549C10.1031 18.3537 7.7121 17.3033 5.17602 17.0569C4.80022 14.8771 5.03025 12.6359 5.84102 10.5779L12 13.9999Z" stroke="black"/>
    <path d="M12 14L21 9L12 4L3 9L12 14ZM12 14L18.16 10.578C18.9705 12.6361 19.2005 14.8772 18.825 17.057C16.2886 17.3032 13.8972 18.3536 12 20.055C10.1031 18.3538 7.71208 17.3034 5.176 17.057C4.8002 14.8772 5.03023 12.636 5.841 10.578L12 14ZM8 20V12.5L12 10.278" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<style scoped>

</style>