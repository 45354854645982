import { createRouter, createWebHistory } from 'vue-router'
import store from "@/store";

const routes = [
    // Home route
    {
        path: '/',
        component: () => import('./components/HomeApp.vue'),
        meta: { requiresAuth: true },
        name: 'home' // Added a name for home route
    },
    // Login route
    {
        path: '/login',
        component: () => import('./components/LoginUser.vue'),
        name: 'login'
    },
    // Users
    {
        path: '/users',
        component: () => import('./components/UserIndex.vue'),
        meta: { requiresAuth: true },
        name: 'users' // Naming convention for listing users
    },
    // Topics
    {
        path: '/topics',
        component: () => import('./components/topics/TopicIndex.vue'),
        meta: { requiresAuth: true },
        name: 'topics' // Naming convention for listing topics
    },
    {
        path: '/topics/create',
        component: () => import('@/components/topics/TopicCreate.vue'),
        meta: { requiresAuth: true },
        name: 'topics-create' // Naming convention for creating a topic
    },
    // Texts
    {
        path: '/texts/create',
        component: () => import('./components/texts/TextCreate.vue'),
        meta: { requiresAuth: true },
        name: 'texts-create' // Naming convention for creating a text
    },
    // Text Representation
    {
        path: '/text-representations',
        component: () => import('./components/TextRepresentations.vue'),
        meta: { requiresAuth: true },
        name: 'text-representations' // Naming convention for listing text representations
    },
];


// Create the router
const router = createRouter({
    history: createWebHistory(),
    routes: routes
})

router.beforeEach((to, from, next) => {
    const isAuthenticated = store.getters.isAuthenticated;

    // Redirect authenticated users trying to access the login page
    if (to.name === 'login' && isAuthenticated) {
        next({ name: 'home' }); // Assuming you have a route named 'home'
        return;
    }

    // Require auth for certain routes
    if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
        next({ name: 'login' });
        return;
    }

    // Avoid redundant navigation to the same route
    if (to.path === from.path) {
        next(false);
        return;
    }

    next();
});



export default router