<script setup>

</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M14 12C12.905 12 12 11.095 12 10C12 9.646 12.103 9.317 12.268 9.027C12.178 9.02 12.092 9 12 9C11.206 9.00524 10.4459 9.32299 9.88447 9.88447C9.32299 10.4459 9.00524 11.206 9 12C9 13.642 10.358 15 12 15C13.641 15 15 13.642 15 12C15 11.908 14.98 11.822 14.973 11.732C14.683 11.897 14.354 12 14 12Z" fill="black"/>
    <path d="M12 5C4.36701 5 2.07301 11.617 2.05201 11.684L1.94601 12L2.05101 12.316C2.07301 12.383 4.36701 19 12 19C19.633 19 21.927 12.383 21.948 12.316L22.054 12L21.949 11.684C21.927 11.617 19.633 5 12 5ZM12 17C6.64901 17 4.57601 13.154 4.07401 12C4.57801 10.842 6.65201 7 12 7C17.351 7 19.424 10.846 19.926 12C19.422 13.158 17.348 17 12 17V17Z" fill="black"/>
  </svg>
</template>

<style scoped>

</style>