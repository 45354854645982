<template>
  <aside class="fixed block top-0 left-0 z-30 w-72 h-full bg-white shadow-lg">
    <div class="flex flex-col h-full justify-between">
      <div class="px-4 py-6">
        <!-- Logo Section -->
        <div class="mb-6">
          <img src="@/assets/logoColor.png" alt="Logo" class="h-32 mx-auto"/>
        </div>
        <nav>
          <div v-for="(route, index) in filteredRoutes" :key="index" class="group">
            <router-link
                v-if="!route.subroutes"
                :to="route.path"
                class="flex items-center justify-start py-2.5 px-4 rounded transition duration-200 hover:bg-blue-500 hover:text-white"
            >
              <component :is="iconComponents[route.icon]" class="h-5 w-5 mr-2" />
              <span>{{ route.name }}</span>
            </router-link>
            <div v-else>
              <button
                  @click="toggleDropdown(index)"
                  class="flex items-center justify-start py-2.5 px-4 rounded transition duration-200 hover:bg-blue-500 hover:text-white w-full"
              >
                <component :is="iconComponents[route.icon]" class="h-5 w-5 mr-2" />
                <span>{{ route.name }}</span>
                <component :is="route.showDropdown ? 'ChevronUpIcon' : 'ChevronDownIcon'" class="h-5 w-5 ml-auto transition-transform" />
              </button>
              <div v-show="route.showDropdown" class="ml-4">
                <router-link
                    v-for="(subroute, subIndex) in route.subroutes"
                    :key="subIndex"
                    :to="subroute.path"
                    class="flex items-center justify-start py-2.5 px-4 rounded transition duration-200 hover:bg-blue-600 hover:text-white"
                >
                  <component :is="iconComponents[subroute.icon]" class="h-5 w-5 mr-2" />
                  <span>{{ subroute.name }}</span>
                </router-link>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <!-- Logout at the bottom -->
      <div class="px-4 py-6">
        <button
            @click="logout"
            class="flex items-center justify-center w-full py-2.5 px-4 rounded transition duration-200 hover:bg-blue-500 hover:text-white"
        >
          <span>Cerrar Sesión</span>
          <LogoutIcon class="h-5 w-5 ml-2 mt-1" />
        </button>
      </div>
    </div>
  </aside>
</template>


<script>
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/vue/16/solid";
import TextIcon from "@/assets/icons/TextIcon.vue";
import TopicIcon from "@/assets/icons/TopicIcon.vue";
import UserIcon from "@/assets/icons/UserIcon.vue";
import LogoutIcon from "@/assets/icons/LogoutIcon.vue";
import PlusIcon from "@/assets/icons/PlusIcon.vue";
import HomeIcon from "@/assets/icons/HomeIcon.vue";
import TextRepresentationIcon from "@/assets/icons/TextRepresentationIcon.vue";
import router from "@/router";
import store from "@/store";
import ShowIcon from "@/assets/icons/ShowIcon.vue";

export default {
  name: 'AppSidebar',
  components: {
    ChevronDownIcon,
    ChevronUpIcon,
    HomeIcon,
    TextIcon,
    TopicIcon,
    UserIcon,
    LogoutIcon,
    PlusIcon,
    TextRepresentationIcon,
    ShowIcon
  },
  computed: {
    iconComponents() {
      return {
        HomeIcon,
        UserIcon,
        TopicIcon,
        TextIcon,
        PlusIcon,
        LogoutIcon,
        TextRepresentationIcon,
        ShowIcon
      };
    },
    filteredRoutes() {
      // Here we filter out the 'Logout' route for rendering purposes
      return this.routes.filter(route => route.name !== 'Logout');
    }
  },
  data() {
    return {
      routes: [
        {name: 'Home', path: '/', icon: 'HomeIcon'},
        {name: 'Usuarios', path: '/users', icon: 'UserIcon'},
        {
          name: 'Temáticas',
          icon: 'TopicIcon',
          subroutes: [
            {name: 'Ver Temáticas', path: '/topics', icon: 'ShowIcon'},
            {name: 'Crear Temática', path: '/topics/create', icon: 'PlusIcon'},
          ],
          showDropdown: false
        },
        {
          name: 'Textos', icon: "TextIcon", subroutes: [
            {name: 'Crear Texto', path: '/texts/create', icon: 'PlusIcon'},
            // ... more subroutes
          ]
        },
        {
          name: "Representación de texto", path: '/text-representations', icon: "TextRepresentationIcon", subroutes: null
        },
        {
          name: 'Logout', path: '/logout', subroutes: null
        }
      ]
    };
  },
  methods: {
    toggleDropdown(index) {
      this.routes[index].showDropdown = !this.routes[index].showDropdown;
    },
    logout() {
      localStorage.removeItem('token');
      store.dispatch('logoutUser');
      router.push('/login');
    }
  },
};
</script>