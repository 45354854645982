import {createStore} from "vuex";

const store = createStore({
    state: {
        token: localStorage.getItem('token') || null,
        userId: localStorage.getItem('userId') || null,
        expirationTime: localStorage.getItem('expirationTime') || null,
    },
    mutations: {
        authenticateUser(state, { token, userId, expirationTime }) {
            state.token = token;
            state.userId = userId;
            state.expirationTime = expirationTime;
            localStorage.setItem('token', token);
            localStorage.setItem('userId', userId);
            localStorage.setItem('expirationTime', expirationTime.toString());
        },
        clearAuthData(state) {
            state.token = null;
            state.userId = null;
            state.expirationTime = null;
            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            localStorage.removeItem('expirationTime');
        }
    },
    actions: {
        authenticateUser({ commit }, authData) {
            commit('authenticateUser', authData);
        },
        logoutUser({ commit }) {
            commit('clearAuthData');
        },
    },
    getters: {
        isAuthenticated(state) {
            return state.token && new Date().getTime() < state.expirationTime;
        },
        userId(state) {
            return state.userId;
        },
    }
});

export default store;